













































































































import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue, Watch } from "vue-property-decorator";
import DxDateBox from "devextreme-vue/date-box";
import fichajes_empleadoModule from "@/store/modules/fichajes_empleado-module";
import centro_trabajoModule from "@/store/modules/centro_trabajo-module";
import geo_loc_fichajeModule from "@/store/modules/geo_loc_fichaje-module";
import DxTagBox from "devextreme-vue/tag-box";
import DxButton from "devextreme-vue/button";

import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
} from "devextreme-vue/form";
import { API } from "@/shared/api";
@Component({
  $_veeValidate: { validator: "new" },
  components: {
    DxDateBox,
    DxTagBox,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
    DxButton,
  },
})
export default class GpsFichajes extends Vue {
  public center = { lat: 10.0, lng: 10.0 };
  public zoom = 6.5;
  //public value_usuarios = [];
  public datos = {
    fecha_desde: new Date(),
    fecha_hasta: new Date(),
    value_usuarios: [] as number[],
  };
  /*public markers = [
    { position: { lat: 10.0, lng: 10.0 } },
    { position: { lat: 11.0, lng: 11.0 } },
    // Agrega más puntos según necesites
  ];*/

  async created() {
    await usuarioModule.getusuarios();
    tipos_fichajeModule.gettipos_fichajes();
    centro_trabajoModule.getcentro_trabajos();
    fichajes_empleadoModule.getfichajes_empleados();
    geo_loc_fichajeModule.getgeo_loc_fichajes(this.datos);
    for (let index = 0; index < usuarioModule.usuarios.length; index++) {
      const element = usuarioModule.usuarios[index];
      this.datos.value_usuarios.push(Number.parseInt(element.id));
    }
  }

  get markers() {
    var ubicaciones = geo_loc_fichajeModule.geo_loc_fichajes.map((x) => {
      return {
        position: {
          lat: Number.parseFloat(x.latitud),
          lng: Number.parseFloat(x.longitud),
        },
        info: x.toJson(),
        id_usuario: x.id_usuario,
        nombre: x.nombre_usuario,
        fecha: x.fecha,
        entrada: x.entrada,
      };
    });
    this.calculateCenter(ubicaciones);
    return ubicaciones;
  }
  @Watch("datos")
  datos_usuarios() {
  }

  get usuarios() {
    return usuarioModule.usuarios;
  }
  get fichajes() {
    return fichajes_empleadoModule.fichajes_empleados;
  }
  get tipos_fichajes() {
    return tipos_fichajeModule.tipos_fichajes;
  }
  get centro_trabajo() {
    return centro_trabajoModule.centro_trabajos;
  }
  public infoWindowOpened = false;

  public infoWindowOptions = {
    //@ts-ignore
    pixelOffset: new google.maps.Size(0, -30),
  };
  public infoWindowPosition: any = null;
  public infoWindowContent = {};
  openInfoWindow(index: number) {
    const marker = this.markers[index];
    var k = "";
    if (marker.entrada) {
      k = "Entrada";
    } else {
      k = "Salida";
    }

    this.infoWindowPosition = marker.position;
    this.infoWindowContent = {
      info: marker.info,
      id_usuario: marker.id_usuario,
      nombre: marker.nombre,
      fecha: marker.fecha,
      entada: k,
    };
    this.infoWindowOpened = true;
  }

  public getImagen(id: number) {
    return API.webApiBaseImages + id + ".png";
  }

  closeInfoWindow() {
    // set
    this.infoWindowOpened = false;
  }

  buscar() {
    geo_loc_fichajeModule.getgeo_loc_fichajes(this.datos);
  }

  customMarker() {
    return {
      url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // URL del ícono personalizado
      //@ts-ignore
      scaledSize: new google.maps.Size(50, 50), // Ajusta el tamaño del ícono}
    };
  }
  calculateCenter(locations: any[]) {
    if (locations.length === 0) return;

    let totalLat = 0;
    let totalLng = 0;
    locations.forEach((location) => {
      totalLat += location.position.lat;
      totalLng += location.position.lng;
    });

    const centerLat = totalLat / locations.length;
    const centerLng = totalLng / locations.length;

    this.center = { lat: centerLat, lng: centerLng };
  }
}
