import { store } from '@/store/store';
import { geo_loc_fichaje } from '@/shared/dtos/geo_loc_fichaje';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'geo_loc_fichajeModule',
    store,
    dynamic: true
})
class geo_loc_fichajeModule extends VuexModule {
    public geo_loc_fichajes: geo_loc_fichaje[] = [];
    public geo_loc_fichaje: geo_loc_fichaje = new geo_loc_fichaje();

    @Action({ commit: 'onGetgeo_loc_fichajes' })
    public async getgeo_loc_fichajes(datos:any) {
        return await ssmHttpService.post(API.geo_loc_fichaje+'/datos_gps',JSON.stringify(datos));
    }

    @Action({ commit: 'onGetgeo_loc_fichaje' })
    public async getgeo_loc_fichaje(id: any) {
        return await ssmHttpService.get(API.geo_loc_fichaje + '/' + id);
    }

    @Action
    public async guardargeo_loc_fichaje(geo_loc_fichaje: geo_loc_fichaje) {
       return await ssmHttpService.post(API.geo_loc_fichaje, geo_loc_fichaje.toJson());
    }

    @Action
    public async modificargeo_loc_fichaje(geo_loc_fichaje: geo_loc_fichaje) {
        return await ssmHttpService.put(API.geo_loc_fichaje + '/' + geo_loc_fichaje.id, geo_loc_fichaje);
    }

    @Action
    public async eliminargeo_loc_fichaje(geo_loc_fichaje: geo_loc_fichaje) {
       return await ssmHttpService.delete(API.geo_loc_fichaje + '/' + geo_loc_fichaje.id, null, false);
    }

    @Mutation
    public onGetgeo_loc_fichajes(res: geo_loc_fichaje[]) {
        this.geo_loc_fichajes = res ? res.map((x) => new geo_loc_fichaje(x)) : []
    }

    @Mutation
    public onGetgeo_loc_fichaje(res: geo_loc_fichaje) {
        this.geo_loc_fichaje = new geo_loc_fichaje(res);
    }

}
export default getModule(geo_loc_fichajeModule);